<template>

</template>
<script>
    import { request } from "../app/api/Client";
    import { mapActions, mapState } from "vuex";
    import { EMAIL_VERIFIED } from "../constants/action-type";

    export default {
        computed : {
            ...mapState(['user'])
        },
        methods : {
            ...mapActions([EMAIL_VERIFIED]),
            async checkVerificationLink() {
                let id = this.$route.params.id || null;
                let signature = this.$route.query.signature || null;

                if(_.isNull(id) || _.isNull(signature)) {
                     await this.$router.push({ name : 'dashboard' });
                     this.$toast.error('Invalid signature');
                    return 0;
                }

                try {
                    let {data : {data, message}} = await request.post('/verify/email',{
                        id,
                        signature
                    });
                    this.$toast.success(message);
                    if(!_.isNull(this.user)) {
                        this[EMAIL_VERIFIED]();
                    }
                    await this.$router.push({ name : 'dashboard' });
                } catch (err) {
                    await this.$router.push({ name : 'dashboard' });
                    this.$toast.error(err.response.data.message);
                }

            }
        },
        mounted() {
            this.checkVerificationLink();
        }
    }
</script>
